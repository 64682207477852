import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

console.log(process.env.REACT_APP_IS_STATIC_USED_FOR)
const container = document.getElementById("root");
const root = createRoot(container);
if (process.env.REACT_APP_IS_STATIC_USED_FOR==='scanner'){
  localStorage.setItem("appType",JSON.stringify({appType:"scanner",API_URL:process.env.REACT_APP_API_SCANNER_URL}))
}else if (process.env.REACT_APP_IS_STATIC_USED_FOR==='transpiler'){
  localStorage.setItem("appType",JSON.stringify({appType:"transpiler",API_URL:process.env.REACT_APP_API_TRANSPILER_URL}))
}else{
  console.log(`Used For Both Application`)
}
root.render(
  <Provider store={store}>
    <App />
    <ToastContainer />
  </Provider>,
);

// If you want your app to work offline and load faster, you can chađinge
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
