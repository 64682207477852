import axios from "axios";

let API_URL = ""

const register = (username, email, password,customUrl) => {
  if (!API_URL){
    API_URL=JSON.parse(localStorage.getItem("appType"))?.API_URL
  }
  let url=API_URL + "/auth/signup"
  if (customUrl){
  url=customUrl
  } 
  return axios.post(url, {
    name:username,
    email,
    password,
  }).then((response) => {
    
    if (response && !customUrl ) {
      localStorage.setItem("username", JSON.stringify({username,email}));
    }
    if (response.data.statusCode!==200){
      throw new Error(response?.data?.message)
    }
    if (customUrl){
      return response
    }
    return  response.data ;
  });;
};

const login = (email, password,customUrl) => {
  if (!API_URL){
    API_URL=JSON.parse(localStorage.getItem("appType"))?.API_URL
  }
 console.log(API_URL)
  let url=API_URL + "/auth/signin"
  if (customUrl){
  url=customUrl
  } 

  return axios
    .post(url, {
      email,
      password,
    })
    .then((response) => {
      if (response?.data?.data?.IdToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.removeItem("username")
      }else{
        if (response?.data?.message.toLowerCase().contains("incorrect username")){
          throw new Error("Authentication failed.Please check your crendentials")}else{
        throw new Error(response?.data?.message || 'Something went wrong')}
      }
      
      return response.data;
    });
};
const verify = (email, code) => {
  return axios
    .post(API_URL + "/auth/verify", {
      email,
      code,
    })
    .then((response) => {
      if (response) {
        localStorage.removeItem("username");
      }

      return response.data;
    });
};
const resendVerificationcode=(email)=>{
  return axios
  .post(API_URL + "/auth/resendVerificationCode", {
    email:email
  })
  .then((response) => {
    if (response) {
      localStorage.setItem("username",JSON.stringify({email}));
    }

    return response.data;
  });
}

const logout = () => {
  localStorage.removeItem("user");
  !process.env.REACT_APP_IS_STATIC_USED_FOR && localStorage.removeItem('appType')
};

const authService = {
  register,
  login,
  logout,
  verify,resendVerificationcode
};

export default authService;
