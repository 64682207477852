import React, { useEffect, useRef, useState } from "react";
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useJwt } from "react-jwt";
import { clearMessage, setMessage } from "../slices/message";
import { setLoading } from "../slices/loading";
import { checkStatus, parse, resetParser } from "../slices/parser";
// import { register } from "../slices/auth";
import { CircularProgress, Typography } from "@mui/material";
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import showToast from "../common/ToastUtils";
import { selectAppTypeSelector } from "../slices/appType";

const Profile = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [file, setFile] = useState([]);
  const [links, setLinks] = useState([]);
  const [htmlLinks, setHtmlLinks] = useState([])
  const [logFileLinks, setlogFileLinks] = useState([])
  const [fileNames, setFileName] = useState([])

  const { decodedToken } = useJwt(currentUser?.data?.IdToken || "null");
  const { loading } = useSelector((state) => state.loading);
  const [successful, setSuccessful] = useState(false);
  const { isParsed, successfullyCompiled, data, retryCount } = useSelector(
    (state) => state.parser
  );
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const selectedType = useSelector(selectAppTypeSelector);
  const fileInputRef = useRef(null);

  const dispatch = useDispatch();
  useEffect(() => {
    document.title = `Corios | ${selectedType.charAt(0).toUpperCase() + selectedType.slice(1)}`;
    return () => {
      document.title = 'Corios';
    };

  }, [selectedType])
  useEffect(() => {
    if (selectedType === 'scanner') {
      if (
        isParsed &&
        !successfullyCompiled &&
        retryCount < process.env.REACT_APP_RETRY_COUNT &&
        data?.requestId
      ) {

        setTimeout(function name() {

          dispatch(checkStatus(isParsed))
            .unwrap()
            .then(() => {
              setSuccessful(true);
            })
            .catch((err) => {
              setSuccessful(false);
            })
        }, 5000);
      } else if (isParsed && successfullyCompiled) {
        setFile([]);
        if (data?.requestId) {
          if (!Array.isArray(data?.requestId)) {
            setLinks([...links, data.requestId]);
          } else {

            setLinks([...links, ...data.requestId]);
          }
        }
        dispatch(setMessage("File Processed Successfully"));
        dispatch(setLoading(false));
        dispatch(
          resetParser({
            isParsed: false,
            successfullyCompiled: false,
            retryCount: 0,
            data: 0,
          })
        );
      } else if (retryCount >= Number(process.env.REACT_APP_RETRY_COUNT)) {
        dispatch(setLoading(false));
        dispatch(
          resetParser({
            isParsed: false,
            successfullyCompiled: false,
            retryCount: 0,
            data: 0,
          })
        );
        showToast(
          'Unable to process file in given time frame, please try later.',
          { type: 'info', position: "top-right" }
        );
        setFile([]);
      }
    } else {
      if (isParsed) {
        if (!Array.isArray(data?.result)) {
          setLinks([...links, data?.result]);
          setHtmlLinks([...htmlLinks, data?.htmlUrl])
          setlogFileLinks([...logFileLinks, data?.logUrl])

        } else {
          setLinks([...links, ...data.result]);
          setHtmlLinks([...htmlLinks, ...data?.htmlUrl])
          setlogFileLinks([...logFileLinks, ...data?.logUrl])
        }
        if (data?.statusCode !== 200) {
          showToast(data?.message, { type: 'error', position: 'top-right' });
        } else {
          showToast(
            "File transpiled successfully. Please have a look at generated URLs",
            { type: "success", position: "top-right" }
          );
        }
        setFile([]);
        dispatch(setLoading(false));
        dispatch(
          resetParser({
            isParsed: false,
            successfullyCompiled: false,
            retryCount: 0,
            data: 0,
          })
        );
      }
    }

    dispatch(clearMessage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParsed, successfullyCompiled, retryCount, dispatch, data]);

  useEffect(() => {
    if (selectedType === 'transpiler') {
      if (links > 0) {
        setLinks([...links, data.requestId]);

      } else {
        data?.requestId && setFile([data?.requestId]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParsed]);

  if (!currentUser) {
    return <Navigate to="/" />;
  }

  const handleMouseOver = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseOut = () => {
    setHoveredIndex(null);
  };

  const handleChange = async (e) => {
    let count = selectedType === 'scanner' ? 1 : 2

    if (selectedType == 'transpiler') {
      if ((Object.values(e.target.files).length + file.length) > count || file.length >= count) {
        showToast(`Please select only ${count} files`, { type: 'warning', position: 'top-right' });
      } else {
        setFile([...file, ...e.target.files]);
        // console.log(e.target.files)
      }
    } else {
      setFile([...e.target.files]);
      console.log(e.target.files)
    };
  }
  const parseFile = (e) => {
    let count = selectedType === 'scanner' ? 1 : 2
    if (file.length > count || file.length === 0) {
      showToast(
        `Please select ${selectedType === 'scanner'
          ? 'a zip file'
          : 'at least 1 file'}`,
        { type: 'warning', position: 'top-right' }
      );
      return;
    } else {
      setSuccessful(false);
      dispatch(setLoading(true));
      if (selectedType === 'scanner') {
        setLinks([])
      }
      const formData = new FormData();
      file.forEach((blob, index) => {
        setFileName([...fileNames, file[index]?.name])
        formData.append('file', blob);
      });
      file.forEach((blob, index) => {
        setFileName([...fileNames, file[index]?.name])
      });
      dispatch(parse({ formData }))
        .unwrap()
        .then((response) => {
          setSuccessful(true);

        })
        .catch((err) => {
          setSuccessful(false);
          dispatch(setLoading(false));
        });
    }

  };
  const clearFileInput = () => {
    // Reset the value of the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  const handleDelete = (fileIndex) => {
    const updatedFiles = [...file];
    updatedFiles.splice(fileIndex, 1);
    setFile(updatedFiles);
  };

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>
          <strong>Welcome</strong> {decodedToken?.name}
        </h3>
      </header>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          width: '100%',
          height: '200px',
          border: '2px dotted gray',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '5px'
        }}
      >
        <input
          disabled={loading}
          type="file"
          accept={selectedType !== 'scanner' ? '.sas' : '.zip'}
          style={{
            position: 'absolute',
            inset: 0,
            zIndex: 2,
            opacity: 0
          }}
          ref={fileInputRef}
          onChange={async (event) => {
            event.preventDefault()
            await handleChange(event)
            clearFileInput()

          }}
          multiple={selectedType === 'scanner' ? false : true}
        />
        <h3> Upload a file</h3>
      </div>
      <div className="my-4" style={{ display: "flex", justifyContent: "center" }}>
        <button
          type="submit"
          disabled={loading}
          autoFocus
          onClick={parseFile}
          className={`btn ${loading ? 'btn-success' : 'btn-primary'} btn-block`}
        >
          {loading ? <CircularProgress size="20px" color="inherit" /> : selectedType === 'transpiler' ? 'Transpile' : 'Scan'}
        </button>
      </div>
      <div className="">
        {file.map((filedata, index) => (

          < Chip
            variant="outlined"
            key={index}
            label={filedata.name}
            onDelete={() => handleDelete(index)}
            deleteIcon={<CloseIcon />}
            color="primary"
            onMouseOver={() => handleMouseOver(index)}
            onMouseOut={handleMouseOut}
            sx={{
              backgroundColor: hoveredIndex === index ? '#e0e0e0' : undefined,
              '&:hover': {
                backgroundColor: '#e0e0e0',
              },
            }}
          />
        ))}
      </div>
      {links.length > 0 &&

        <h3 className="mt-5 mb-3">{selectedType === 'transpiler' ? 'Transpiled Links' : 'Scanner Request Response:'}</h3>
      }{
        selectedType == 'transpiler' ? links.map((link, _ind) => (
          <div
            key={_ind}
            style={{
              padding: '12px 20px',
              border: '1px solid gainsboro',
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px',
              background: 'white',
              boxShadow: '0 0 2px rgba(0,0,0,0.1)',
              flexDirection: "column",
              gap: "12px"
            }}
          >
            <div style={{ fontWeight: "bold", fontSize: "20px" }}> {decodeURIComponent(link.split('?')[0]?.split('/')?.pop()?.split('.')[0])}.sas</div>
            <h6 className="m-0">
              {'Transpiled'} - <a href={link}>{decodeURIComponent(link.split('?')[0]?.split('/')?.pop())}</a>
            </h6>
            <h6 className="m-0">
              {'HTML'} - <a href={htmlLinks[_ind]}>{decodeURIComponent(htmlLinks[_ind]?.split('?')[0]?.split('/')?.pop())}</a>
            </h6>
            <h6 className="m-0">
              {'Log File'} - <a href={logFileLinks[_ind]}>{decodeURIComponent(logFileLinks[_ind]?.split('?')[0]?.split('/')?.pop())}</a>
            </h6>
          </div>
        ))
          :
          links.length > 0 && <Typography variant="body1"
            style={{

              color: 'green', // Customize the color here
              fontWeight: 'bold',
              margin: '16px', // Customize the margin here
            }}>{`Your file has been successfully scanned with request ID:${links.pop()}. Corios Team will contact you when analytics are available for you.`}</Typography>
      }


    </div>
  );
};


export default Profile;
