// appTypeSlice.js

import { createSlice } from '@reduxjs/toolkit';

const appTypeSlice = createSlice({
  name: 'appType',
  initialState: {
    selectedType:  "",
    API_URL: ""

  },
  reducers: {
    selectAppType: (state, action) => {
        state.selectedType = action.payload;
      if (action.payload==="transpiler"){
        state.API_URL=process.env.REACT_APP_API_TRANSPILER_URL   
      }else{
        state.API_URL=process.env.REACT_APP_API_SCANNER_URL
      }
      console.log("API URL")
      localStorage.setItem("appType",JSON.stringify({appType: action.payload,API_URL:state.API_URL}))
    },
    setAppType: (state, action) => {
      return { appType: action.payload };
    }
  },
});

// export const { selectAppType } = appTypeSlice.actions;
export const selectAppTypeSelector = (state) =>state.appType.selectedType || JSON.parse(localStorage.getItem("appType"))?.appType ;
// export default appTypeSlice.reducer;

const { reducer,actions } = appTypeSlice;
export const { selectAppType } = actions

export default reducer;

