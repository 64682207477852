import axios from "axios";
let API_URL = ""
const parser = (data, config, customUrl) => {
  if (!API_URL) {
    API_URL = JSON.parse(localStorage.getItem("appType"))?.API_URL || ""
  }


  let url = API_URL + '/' +process.env.REACT_APP_IS_STATIC_USED_FOR 
  if (customUrl) {
    url = customUrl
  }

  config.headers = {
    ...config.headers,
    // "Content-Type": "multipart/form-data",
    // "Accept":"*/*"
  }


  return axios.post(url, data, { headers: config.headers }).then((response) => {
    return customUrl ? response : response?.data;
  });;
};
const checkStatus = (data, config) => {
  if (!API_URL) {
    API_URL = JSON.parse(localStorage.getItem("appType"))?.API_URL
  }

  return axios.post(API_URL + "/scanner/checkStatus", data, config).then((response) => {
    if (response) {
      console.log(response)
    }

    return response.data;
  });;
};
let parserService = {
  parser, checkStatus
}

export default parserService

